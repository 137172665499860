/* tslint:disable */
/* eslint-disable */
/**
 * Conjunct4
 * Tournament api for connect 4.
 *
 * The version of the OpenAPI document: 0.2.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RoundAllOf } from './RoundAllOf';
import {
    RoundAllOfFromJSON,
    RoundAllOfFromJSONTyped,
    RoundAllOfToJSON,
} from './RoundAllOf';
import type { RoundAllOfHistory } from './RoundAllOfHistory';
import {
    RoundAllOfHistoryFromJSON,
    RoundAllOfHistoryFromJSONTyped,
    RoundAllOfHistoryToJSON,
} from './RoundAllOfHistory';
import type { RoundHeader } from './RoundHeader';
import {
    RoundHeaderFromJSON,
    RoundHeaderFromJSONTyped,
    RoundHeaderToJSON,
} from './RoundHeader';

/**
 * 
 * @export
 * @interface Round
 */
export interface Round {
    /**
     * Id of the round.
     * @type {string}
     * @memberof Round
     */
    id: string;
    /**
     * State of the round.
     * @type {string}
     * @memberof Round
     */
    state: RoundStateEnum;
    /**
     * The result of the round, returned if the round is in `finished`
     * state:  
     * `win` if one of the players won normally;  
     * `time` if one of the players ran out of time;  
     * `dsq` if one or both players did not accept the game;  
     * `draw` if draw.
     * @type {string}
     * @memberof Round
     */
    result?: RoundResultEnum;
    /**
     * Returned if the game is finished with result `win`, `time` or `dsq` if only one of the players did not accept the game.
     * @type {string}
     * @memberof Round
     */
    winner?: RoundWinnerEnum;
    /**
     * Time in seconds until the round. After the round transitions into `starting` states, becomes time in seconds until the accept period ends. Goes negative after the round transitions into the `playing` state.
     * @type {number}
     * @memberof Round
     */
    eta: number;
    /**
     * The time span in seconds for the players to accept the round  within. Returned for starting and planned rounds only.
     * @type {number}
     * @memberof Round
     */
    acceptDuration?: number;
    /**
     * Id of the game this round belongs to.
     * @type {string}
     * @memberof Round
     */
    game: string;
    /**
     * Columns of the game board, from left to right. Returned only in 
     * the `playing` or `finished` state.  
     * Each column contains cells ordered from bottop to top.
     * 
     * | cell |               |
     * | ---- | ------------- |
     * |   0  | Empty cell    |
     * |  -1  | Enemy's chip  |
     * |   1  | Player's chip |
     * @type {Array<Array<number>>}
     * @memberof Round
     */
    columns?: Array<Array<number>>;
    /**
     * Whose turn it is now. Returned if the game is in `playing` state.
     * @type {string}
     * @memberof Round
     */
    turn?: RoundTurnEnum;
    /**
     * Simple chess timer for the player in seconds. Returned if the round is in `playing` state.
     * @type {number}
     * @memberof Round
     */
    playerTimer?: number;
    /**
     * Simple chess timer for the enemy in seconds. Returned if the round is in `playing` state.
     * @type {number}
     * @memberof Round
     */
    enemyTimer?: number;
    /**
     * 
     * @type {RoundAllOfHistory}
     * @memberof Round
     */
    history?: RoundAllOfHistory;
}


/**
 * @export
 */
export const RoundStateEnum = {
    Planned: 'planned',
    Starting: 'starting',
    Playing: 'playing',
    Finished: 'finished'
} as const;
export type RoundStateEnum = typeof RoundStateEnum[keyof typeof RoundStateEnum];

/**
 * @export
 */
export const RoundResultEnum = {
    Win: 'win',
    Time: 'time',
    Dsq: 'dsq',
    Draw: 'draw'
} as const;
export type RoundResultEnum = typeof RoundResultEnum[keyof typeof RoundResultEnum];

/**
 * @export
 */
export const RoundWinnerEnum = {
    Player: 'player',
    Enemy: 'enemy'
} as const;
export type RoundWinnerEnum = typeof RoundWinnerEnum[keyof typeof RoundWinnerEnum];

/**
 * @export
 */
export const RoundTurnEnum = {
    Player: 'player',
    Enemy: 'enemy'
} as const;
export type RoundTurnEnum = typeof RoundTurnEnum[keyof typeof RoundTurnEnum];


/**
 * Check if a given object implements the Round interface.
 */
export function instanceOfRound(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "state" in value;
    isInstance = isInstance && "eta" in value;
    isInstance = isInstance && "game" in value;

    return isInstance;
}

export function RoundFromJSON(json: any): Round {
    return RoundFromJSONTyped(json, false);
}

export function RoundFromJSONTyped(json: any, ignoreDiscriminator: boolean): Round {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'state': json['state'],
        'result': !exists(json, 'result') ? undefined : json['result'],
        'winner': !exists(json, 'winner') ? undefined : json['winner'],
        'eta': json['eta'],
        'acceptDuration': !exists(json, 'acceptDuration') ? undefined : json['acceptDuration'],
        'game': json['game'],
        'columns': !exists(json, 'columns') ? undefined : json['columns'],
        'turn': !exists(json, 'turn') ? undefined : json['turn'],
        'playerTimer': !exists(json, 'playerTimer') ? undefined : json['playerTimer'],
        'enemyTimer': !exists(json, 'enemyTimer') ? undefined : json['enemyTimer'],
        'history': !exists(json, 'history') ? undefined : RoundAllOfHistoryFromJSON(json['history']),
    };
}

export function RoundToJSON(value?: Round | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'state': value.state,
        'result': value.result,
        'winner': value.winner,
        'eta': value.eta,
        'acceptDuration': value.acceptDuration,
        'game': value.game,
        'columns': value.columns,
        'turn': value.turn,
        'playerTimer': value.playerTimer,
        'enemyTimer': value.enemyTimer,
        'history': RoundAllOfHistoryToJSON(value.history),
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Conjunct4
 * Tournament api for connect 4.
 *
 * The version of the OpenAPI document: 0.2.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    Bracket,
    instanceOfBracket,
    BracketFromJSON,
    BracketFromJSONTyped,
    BracketToJSON,
} from './Bracket';
import {
    User,
    instanceOfUser,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * @type BracketLeft
 * Left node of the bracket, is always User for leaf nodes. Even after the child bracket node finishes it will stay as Bracket. You can get its winner from `left.winner`.
 * @export
 */
export type BracketLeft = Bracket | User;

export function BracketLeftFromJSON(json: any): BracketLeft {
    return BracketLeftFromJSONTyped(json, false);
}

export function BracketLeftFromJSONTyped(json: any, ignoreDiscriminator: boolean): BracketLeft {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...BracketFromJSONTyped(json, true), ...UserFromJSONTyped(json, true) };
}

export function BracketLeftToJSON(value?: BracketLeft | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfBracket(value)) {
        return BracketToJSON(value as Bracket);
    }
    if (instanceOfUser(value)) {
        return UserToJSON(value as User);
    }

    return {};
}


/* tslint:disable */
/* eslint-disable */
/**
 * Conjunct4
 * Tournament api for connect 4.
 *
 * The version of the OpenAPI document: 0.2.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GameError,
  ModifyRoundBoardStateRequest,
  Round,
} from '../models';
import {
    GameErrorFromJSON,
    GameErrorToJSON,
    ModifyRoundBoardStateRequestFromJSON,
    ModifyRoundBoardStateRequestToJSON,
    RoundFromJSON,
    RoundToJSON,
} from '../models';

export interface ForfeitRoundRequest {
    roundid: string;
}

export interface GetRoundBoardStateRequest {
    roundid: string;
}

export interface ModifyRoundBoardStateOperationRequest {
    roundid: string;
    modifyRoundBoardStateRequest: ModifyRoundBoardStateRequest;
}

/**
 * 
 */
export class RoundApi extends runtime.BaseAPI {

    /**
     * Attempt to give up the round.
     * Give up the round.
     */
    async forfeitRoundRaw(requestParameters: ForfeitRoundRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.roundid === null || requestParameters.roundid === undefined) {
            throw new runtime.RequiredError('roundid','Required parameter requestParameters.roundid was null or undefined when calling forfeitRound.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/round/{roundid}`.replace(`{${"roundid"}}`, encodeURIComponent(String(requestParameters.roundid))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Attempt to give up the round.
     * Give up the round.
     */
    async forfeitRound(requestParameters: ForfeitRoundRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.forfeitRoundRaw(requestParameters, initOverrides);
    }

    /**
     * Get the state of the round from the perspective of an authorized player. 
     * Get game state.
     */
    async getRoundBoardStateRaw(requestParameters: GetRoundBoardStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Round>> {
        if (requestParameters.roundid === null || requestParameters.roundid === undefined) {
            throw new runtime.RequiredError('roundid','Required parameter requestParameters.roundid was null or undefined when calling getRoundBoardState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/round/{roundid}`.replace(`{${"roundid"}}`, encodeURIComponent(String(requestParameters.roundid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RoundFromJSON(jsonValue));
    }

    /**
     * Get the state of the round from the perspective of an authorized player. 
     * Get game state.
     */
    async getRoundBoardState(requestParameters: GetRoundBoardStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Round> {
        const response = await this.getRoundBoardStateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Attempt a move by an authorized player at a specified column. Returns the state after the move if successful. 
     * Make a move.
     */
    async modifyRoundBoardStateRaw(requestParameters: ModifyRoundBoardStateOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Round>> {
        if (requestParameters.roundid === null || requestParameters.roundid === undefined) {
            throw new runtime.RequiredError('roundid','Required parameter requestParameters.roundid was null or undefined when calling modifyRoundBoardState.');
        }

        if (requestParameters.modifyRoundBoardStateRequest === null || requestParameters.modifyRoundBoardStateRequest === undefined) {
            throw new runtime.RequiredError('modifyRoundBoardStateRequest','Required parameter requestParameters.modifyRoundBoardStateRequest was null or undefined when calling modifyRoundBoardState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/round/{roundid}`.replace(`{${"roundid"}}`, encodeURIComponent(String(requestParameters.roundid))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ModifyRoundBoardStateRequestToJSON(requestParameters.modifyRoundBoardStateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RoundFromJSON(jsonValue));
    }

    /**
     * Attempt a move by an authorized player at a specified column. Returns the state after the move if successful. 
     * Make a move.
     */
    async modifyRoundBoardState(requestParameters: ModifyRoundBoardStateOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Round> {
        const response = await this.modifyRoundBoardStateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/* tslint:disable */
/* eslint-disable */
/**
 * Conjunct4
 * Tournament api for connect 4.
 *
 * The version of the OpenAPI document: 0.2.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';

export interface CreateSessionRequest {
    redirect: string;
}

/**
 * 
 */
export class AuthenticationApi extends runtime.BaseAPI {

    /**
     * Redirects to the Discord OAuth, collects token, sets the session cookie `conjunct4_session`, then redirects back to the `redirect` url. 
     * Authenticate user via Discord.
     */
    async createSessionRaw(requestParameters: CreateSessionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.redirect === null || requestParameters.redirect === undefined) {
            throw new runtime.RequiredError('redirect','Required parameter requestParameters.redirect was null or undefined when calling createSession.');
        }

        const queryParameters: any = {};

        if (requestParameters.redirect !== undefined) {
            queryParameters['redirect'] = requestParameters.redirect;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/session`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Redirects to the Discord OAuth, collects token, sets the session cookie `conjunct4_session`, then redirects back to the `redirect` url. 
     * Authenticate user via Discord.
     */
    async createSession(requestParameters: CreateSessionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createSessionRaw(requestParameters, initOverrides);
    }

    /**
     * Deletes the session cookie.
     * Logout.
     */
    async destroySessionRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/session`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes the session cookie.
     * Logout.
     */
    async destroySession(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.destroySessionRaw(initOverrides);
    }

}

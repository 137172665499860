import { useEffect, useState, useCallback } from "react";
import { useInterval } from "usehooks-ts";
import { useNavigate, Link } from 'react-router-dom'
import { Navbar, Container, Nav, Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import style from './ControlPanelLayout.module.scss';
import routes, { Route, findRoute, routeKeys } from "routes";
import { apis, i18n, LanguageEnum, TranslationKey } from "services";
import UserPanel from "components/UserPanel";
import { useStore } from "MST/StoreProvider";
import { observer } from "mobx-react-lite";
import { ErrorBoundary } from "components/ErrorBoundary";
import { Online, Offline, Detector } from 'react-detect-offline';
import OfflinePage from "pages/OfflinePage";

type LoginLayoutProps = {
    children: React.ReactNode;
    route : Route;
}

export default observer(function ControlPanelLayout({ route, children } : LoginLayoutProps) {
    const navigate = useNavigate();
    const [apiVersion, setApiVersion] = useState<string>("loading...");
    const store = useStore();

    // ping the server to see if we are logged in
    useEffect(() => {
        // this will work fine for as long as the interval function only touches MST
        // for for as long as anything that follows after doesnt require user to be set.
        $onIntervalCheckLogin();
        
        apis.misc.getApiVersion().then(apiVersion => {
            setApiVersion(`Version ${apiVersion.apiVersion} | Build ${apiVersion.build}`);
        });
    }, []);

    useInterval($onIntervalCheckLogin, 5000);

    function $onIntervalCheckLogin() : Promise<void> {
        return apis.user.getSelf().then(user => {
            // no-op, we're logged in
            store.setUser(user);
        }).catch(e => {
            if ( navigator.onLine ) {
                store.setUser(null);
                navigate(findRoute( routeKeys.LOGIN ).path);    
            }
        });
    }

    const logoutUser = useCallback((e) => {
        apis.authentication.destroySession().then(() => {
            navigate(findRoute( routeKeys.LOGIN ).path);
        })
    }, [
        navigate
    ]);

    const renderTooltip = useCallback((props) => {
        return <Tooltip {...props}>
            {apiVersion}
        </Tooltip>
    }, [
        apiVersion
    ]);

    const render = useCallback((online) => {
        return (
            <>
                <div className={style.dashboardBackground}></div>
                <Navbar bg="light" expand="lg">
                    <Container>
                        <Navbar.Brand href={'#'}>
                            <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} trigger={["hover", "focus"]} overlay={renderTooltip}>
                                <span><UserPanel user={store.user} /></span>
                            </OverlayTrigger>
                        </Navbar.Brand>
                        <div className={style.navOpenContainer}>
                            <Button disabled={!online} className={`${style.logoutButton} ${style.freeButton}`} variant="secondary" onClick={logoutUser}>{i18n(store.language as LanguageEnum, "button.logout")}</Button>
                            <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        </div>
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="me-auto">
                                { routes.filter( r => r.showInNav ).map( nroute => <Nav.Link disabled={!online} as={Link} to={nroute.path} key={nroute.key} active={nroute === route}>{i18n(store.language as LanguageEnum, nroute.title as TranslationKey)}</Nav.Link> ) }
                            </Nav>
                            <Button disabled={!online} className={`${style.logoutButton} ${style.menuButton}`} variant="secondary" onClick={logoutUser}>{i18n(store.language as LanguageEnum, "button.logout")}</Button>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
                <div className={`${style.dashboardForeground} ${route.params?.always_scroll ? style.dashboardScroll : ''}`}>
                    <ErrorBoundary userId={store?.user?.id}>
                        <Online>
                            {children}
                        </Online>
                        <Offline>
                            <OfflinePage></OfflinePage>
                        </Offline>
                    </ErrorBoundary>
                </div>
            </>
        );
    }, [
        store.user,
        logoutUser,
        renderTooltip,
        route,
        store.language,
        children
    ]);

    return (<Detector render={render} />);
});
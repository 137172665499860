/* tslint:disable */
/* eslint-disable */
/**
 * Conjunct4
 * Tournament api for connect 4.
 *
 * The version of the OpenAPI document: 0.2.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Tournament,
  TournamentHeader,
} from '../models';
import {
    TournamentFromJSON,
    TournamentToJSON,
    TournamentHeaderFromJSON,
    TournamentHeaderToJSON,
} from '../models';

export interface GetTournamentRequest {
    tournamentId: string;
}

/**
 * 
 */
export class TournamentApi extends runtime.BaseAPI {

    /**
     * Get detailed tournament information and brackets.
     * Get tournament info.
     */
    async getTournamentRaw(requestParameters: GetTournamentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Tournament>> {
        if (requestParameters.tournamentId === null || requestParameters.tournamentId === undefined) {
            throw new runtime.RequiredError('tournamentId','Required parameter requestParameters.tournamentId was null or undefined when calling getTournament.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/tournament/{tournamentId}`.replace(`{${"tournamentId"}}`, encodeURIComponent(String(requestParameters.tournamentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TournamentFromJSON(jsonValue));
    }

    /**
     * Get detailed tournament information and brackets.
     * Get tournament info.
     */
    async getTournament(requestParameters: GetTournamentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Tournament> {
        const response = await this.getTournamentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a list of tournaments available to player.
     * Get tournaments.
     */
    async getTournamentIdsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TournamentHeader>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/tournament`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TournamentHeaderFromJSON));
    }

    /**
     * Get a list of tournaments available to player.
     * Get tournaments.
     */
    async getTournamentIds(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TournamentHeader>> {
        const response = await this.getTournamentIdsRaw(initOverrides);
        return await response.value();
    }

}

/* tslint:disable */
/* eslint-disable */
/**
 * Conjunct4
 * Tournament api for connect 4.
 *
 * The version of the OpenAPI document: 0.2.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * Basic tournament info. `winner` is only returned for finished tournaments.
 * @export
 * @interface TournamentHeader
 */
export interface TournamentHeader {
    /**
     * Id of the tournament.
     * @type {string}
     * @memberof TournamentHeader
     */
    id: string;
    /**
     * Name of the tournament.
     * @type {string}
     * @memberof TournamentHeader
     */
    name: string;
    /**
     * Status of the tournament.
     * @type {string}
     * @memberof TournamentHeader
     */
    status: TournamentHeaderStatusEnum;
    /**
     * 
     * @type {User}
     * @memberof TournamentHeader
     */
    winner?: User;
}


/**
 * @export
 */
export const TournamentHeaderStatusEnum = {
    InProgress: 'inProgress',
    Finished: 'finished'
} as const;
export type TournamentHeaderStatusEnum = typeof TournamentHeaderStatusEnum[keyof typeof TournamentHeaderStatusEnum];


/**
 * Check if a given object implements the TournamentHeader interface.
 */
export function instanceOfTournamentHeader(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function TournamentHeaderFromJSON(json: any): TournamentHeader {
    return TournamentHeaderFromJSONTyped(json, false);
}

export function TournamentHeaderFromJSONTyped(json: any, ignoreDiscriminator: boolean): TournamentHeader {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'status': json['status'],
        'winner': !exists(json, 'winner') ? undefined : UserFromJSON(json['winner']),
    };
}

export function TournamentHeaderToJSON(value?: TournamentHeader | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'status': value.status,
        'winner': UserToJSON(value.winner),
    };
}


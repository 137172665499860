import './App.css';

import LoginLayout from './layouts/LoginLayout.tsx';
import { Routes, Route } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner'

import React, { Suspense } from 'react';
import routes from './routes'
import setTitle from './helpers/setTitle';

import { Provider as UseHttpProvider } from 'use-http'
import PBToastProvider from './hooks/usePBToast';

import style from './App.module.scss'
import { ErrorBoundary } from 'components/ErrorBoundary';

const HttpInterceptor = function HttpInterceptor({ children }) {
  const httpOptions = {
    interceptors : {
      response : async ({ response }) => {
        return response;
      }
    },
    headers : {

    },
    cachePolicy: 'no-cache',
    credentials: 'include'
  };
  

  return <UseHttpProvider options={httpOptions}>{children}</UseHttpProvider>
};


// Set defaults unless provided otherwise
routes.forEach(route => {
  const defaults = {
    layout : LoginLayout,
    hidden : false,
    fallback : () => <div className={style.loading}>
        {/*Loading<br />*/}
        <Spinner animation="grow" variant="primary" />
      </div>,
    pageProps : {}
  };

  Object.keys(defaults).forEach(propName => {
    if ( route[propName] === undefined ) {
      route[propName] = defaults[propName];
    }
  });

  if ( route.key === undefined ) {
    console.warn( "Route needs a key property!", routes );
  }
});

function App(props) {
  return (
    <HttpInterceptor>
      <PBToastProvider>
        <ErrorBoundary>
          <Suspense fallback={<div className={style.loading}><Spinner animation="grow" variant="primary" /></div>}>
            <Routes>
              {routes.map(route => (
                <Route
                  key={route.key}
                  path={route.path}
                  exact={route.exact}
                  element={
                    <route.layout setTitle={setTitle} title={route.title} route={route} params={route.params}>
                      <Suspense fallback={<route.fallback />}>
                        <route.component setTitle={setTitle} title={route.title} route={route} params={route.params} />
                      </Suspense>
                    </route.layout>
                  }
                />
              ))}
            </Routes>
          </Suspense>
        </ErrorBoundary>
      </PBToastProvider>
    </HttpInterceptor>
  );
}

export default App;

/* tslint:disable */
/* eslint-disable */
/**
 * Conjunct4 Test
 * Testing api for the Conjunct4 Tournament api.
 *
 * The version of the OpenAPI document: 0.1.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateGameRequest,
} from '../models';
import {
    CreateGameRequestFromJSON,
    CreateGameRequestToJSON,
} from '../models';

export interface CreateGameOperationRequest {
    createGameRequest: CreateGameRequest;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Create a new game, will create a dummy user if doesn\'t exist.
     */
    async createGameRaw(requestParameters: CreateGameOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.createGameRequest === null || requestParameters.createGameRequest === undefined) {
            throw new runtime.RequiredError('createGameRequest','Required parameter requestParameters.createGameRequest was null or undefined when calling createGame.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/game`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateGameRequestToJSON(requestParameters.createGameRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create a new game, will create a dummy user if doesn\'t exist.
     */
    async createGame(requestParameters: CreateGameOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createGameRaw(requestParameters, initOverrides);
    }

}

import { types, onSnapshot, Instance } from 'mobx-state-tree'
import { LanguageEnum, User } from 'services';
import { UserType } from './user';

//import { fromLocalStorage, toLocalStorage } from 'helpers/localstorage';

const RootType = types
    .model({
        user: types.maybeNull(UserType),
        language: types.optional(types.string, 'en'),
    }).actions(self => ({
        setUser(user : User) {
            if (!user) {
                self.user = null;
                return;
            }

            const newUser = {
                id              : user.id,
                username        : user.username,
                display         : user.display ?? null,
                discriminator   : user.discriminator,
                avatar          : user.avatar ?? null
            }

            self.user = newUser;
        },

        setLanguage(language : LanguageEnum) {
            self.language = language;
            localStorage.setItem('language', language);
        },
    })).views(self => ({
        
    }));

export type RootStoreModel = Instance<typeof RootType>;

export default function createStore() : RootStoreModel {
    const store = RootType.create({
        user: null,
        language: localStorage.getItem('language') ?? 'en',
    });

    onSnapshot( store, (snapshot) => {
        console.log( "onSnapshot", snapshot );
    });

    return store;
};
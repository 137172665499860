import { Modal, Button } from 'react-bootstrap'

export interface AlertModalProps {
    show: boolean,
    onCancel?: any,
    children: React.ReactNode,
    title : string
}

export default function AlertModal({ title, children, onCancel, show } : AlertModalProps) {
    return (
        <Modal show={show} onHide={onCancel} animation={true} backdrop="static">
            <Modal.Header closeButton={!!onCancel}>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {children}
            </Modal.Body>
            <Modal.Footer>
                {onCancel &&
                    <Button variant="primary" onClick={onCancel}>
                        OK
                    </Button>
                }
            </Modal.Footer>
        </Modal>
    );
}
import { AuthenticationApi, Configuration as StandardConfiguration, RoundApi, MiscApi, TournamentApi, UserApi, MatchApi } from "./cj4";
import { DefaultApi as TestApi, Configuration as TestConfiguration } from "./cj4test"

// @TODO(karou): Ideally we would like to have an interceptor here that detects if the user is no longer authenticated and redirects to the login page.
// Not sure if that works with useNavigate().
// Also it would have to be active ONLY when the DashboardLayout is rendering.

const standardConfiguration : StandardConfiguration = new StandardConfiguration({
    basePath        : window._env_.REACT_APP_API,
    credentials     : 'include', 
});

const testConfiguration : TestConfiguration = new TestConfiguration({
    basePath        : window._env_.REACT_APP_API,
    credentials     : 'include', 
});

export const apis = {
    authentication  : new AuthenticationApi(standardConfiguration),
    round           : new RoundApi(standardConfiguration),
    tournament      : new TournamentApi(standardConfiguration),
    match           : new MatchApi(standardConfiguration),
    user        	: new UserApi(standardConfiguration),
    misc            : new MiscApi(standardConfiguration),

    test            : new TestApi(testConfiguration),
}

export * from './lang';
export * from './cj4';
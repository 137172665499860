/* tslint:disable */
/* eslint-disable */
/**
 * Conjunct4
 * Tournament api for connect 4.
 *
 * The version of the OpenAPI document: 0.2.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RoundHeader
 */
export interface RoundHeader {
    /**
     * Id of the round.
     * @type {string}
     * @memberof RoundHeader
     */
    id: string;
    /**
     * State of the round.
     * @type {string}
     * @memberof RoundHeader
     */
    state: RoundHeaderStateEnum;
    /**
     * The result of the round, returned if the round is in `finished`
     * state:  
     * `win` if one of the players won normally;  
     * `time` if one of the players ran out of time;  
     * `dsq` if one or both players did not accept the game;  
     * `draw` if draw.
     * @type {string}
     * @memberof RoundHeader
     */
    result?: RoundHeaderResultEnum;
    /**
     * Returned if the game is finished with result `win`, `time` or `dsq` if only one of the players did not accept the game.
     * @type {string}
     * @memberof RoundHeader
     */
    winner?: RoundHeaderWinnerEnum;
    /**
     * Time in seconds until the round. After the round transitions into `starting` states, becomes time in seconds until the accept period ends. Goes negative after the round transitions into the `playing` state.
     * @type {number}
     * @memberof RoundHeader
     */
    eta: number;
    /**
     * The time span in seconds for the players to accept the round  within. Returned for starting and planned rounds only.
     * @type {number}
     * @memberof RoundHeader
     */
    acceptDuration?: number;
}


/**
 * @export
 */
export const RoundHeaderStateEnum = {
    Planned: 'planned',
    Starting: 'starting',
    Playing: 'playing',
    Finished: 'finished'
} as const;
export type RoundHeaderStateEnum = typeof RoundHeaderStateEnum[keyof typeof RoundHeaderStateEnum];

/**
 * @export
 */
export const RoundHeaderResultEnum = {
    Win: 'win',
    Time: 'time',
    Dsq: 'dsq',
    Draw: 'draw'
} as const;
export type RoundHeaderResultEnum = typeof RoundHeaderResultEnum[keyof typeof RoundHeaderResultEnum];

/**
 * @export
 */
export const RoundHeaderWinnerEnum = {
    Player: 'player',
    Enemy: 'enemy'
} as const;
export type RoundHeaderWinnerEnum = typeof RoundHeaderWinnerEnum[keyof typeof RoundHeaderWinnerEnum];


/**
 * Check if a given object implements the RoundHeader interface.
 */
export function instanceOfRoundHeader(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "state" in value;
    isInstance = isInstance && "eta" in value;

    return isInstance;
}

export function RoundHeaderFromJSON(json: any): RoundHeader {
    return RoundHeaderFromJSONTyped(json, false);
}

export function RoundHeaderFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoundHeader {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'state': json['state'],
        'result': !exists(json, 'result') ? undefined : json['result'],
        'winner': !exists(json, 'winner') ? undefined : json['winner'],
        'eta': json['eta'],
        'acceptDuration': !exists(json, 'acceptDuration') ? undefined : json['acceptDuration'],
    };
}

export function RoundHeaderToJSON(value?: RoundHeader | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'state': value.state,
        'result': value.result,
        'winner': value.winner,
        'eta': value.eta,
        'acceptDuration': value.acceptDuration,
    };
}


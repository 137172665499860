/* tslint:disable */
/* eslint-disable */
/**
 * Conjunct4
 * Tournament api for connect 4.
 *
 * The version of the OpenAPI document: 0.2.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BracketLeft } from './BracketLeft';
import {
    BracketLeftFromJSON,
    BracketLeftFromJSONTyped,
    BracketLeftToJSON,
} from './BracketLeft';
import type { BracketRight } from './BracketRight';
import {
    BracketRightFromJSON,
    BracketRightFromJSONTyped,
    BracketRightToJSON,
} from './BracketRight';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * Bracket of the tournament. Brackets form a binary tree with the players at the leaf nodes. `winner` of the bracket is returned only once the bracket is finished. Winner is not returned if the bracket is discarded (both players dsq).
 * @export
 * @interface Bracket
 */
export interface Bracket {
    /**
     * Id of the bracket.
     * @type {string}
     * @memberof Bracket
     */
    id: string;
    /**
     * Id of the game associated with this bracket. Returned if the bracket is in `awaitingGame` state, or in `finished` state if the game for the bracket actually happened.
     * @type {string}
     * @memberof Bracket
     */
    game?: string;
    /**
     * Status of the bracket.
     * 
     * `awaitingGame`: Bracket has both players assigned, and the game has
     * been created  
     * `awaitingBracket`: Bracket does not have players assigned yet, but
     * has child brackets results of which are being awaited  
     * `finished`: Bracket has a result, either a winner, or both players
     * disqualified
     * @type {string}
     * @memberof Bracket
     */
    status: BracketStatusEnum;
    /**
     * 
     * @type {BracketLeft}
     * @memberof Bracket
     */
    left: BracketLeft;
    /**
     * 
     * @type {BracketRight}
     * @memberof Bracket
     */
    right: BracketRight;
    /**
     * 
     * @type {User}
     * @memberof Bracket
     */
    winner?: User;
}


/**
 * @export
 */
export const BracketStatusEnum = {
    AwaitingGame: 'awaitingGame',
    AwaitingBracket: 'awaitingBracket',
    Finished: 'finished'
} as const;
export type BracketStatusEnum = typeof BracketStatusEnum[keyof typeof BracketStatusEnum];


/**
 * Check if a given object implements the Bracket interface.
 */
export function instanceOfBracket(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "left" in value;
    isInstance = isInstance && "right" in value;

    return isInstance;
}

export function BracketFromJSON(json: any): Bracket {
    return BracketFromJSONTyped(json, false);
}

export function BracketFromJSONTyped(json: any, ignoreDiscriminator: boolean): Bracket {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'game': !exists(json, 'game') ? undefined : json['game'],
        'status': json['status'],
        'left': BracketLeftFromJSON(json['left']),
        'right': BracketRightFromJSON(json['right']),
        'winner': !exists(json, 'winner') ? undefined : UserFromJSON(json['winner']),
    };
}

export function BracketToJSON(value?: Bracket | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'game': value.game,
        'status': value.status,
        'left': BracketLeftToJSON(value.left),
        'right': BracketRightToJSON(value.right),
        'winner': UserToJSON(value.winner),
    };
}


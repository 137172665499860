import React from 'react';
import { createRoot } from 'react-dom/client';

import './index.css';
import reportWebVitals from './reportWebVitals';
import { StoreProvider } from './MST/StoreProvider';
import { BrowserRouter } from "react-router-dom";

import "bootswatch/dist/sandstone/bootstrap.min.css";
import App from './App';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <StoreProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </StoreProvider>
);

reportWebVitals();

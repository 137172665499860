/* tslint:disable */
/* eslint-disable */
/**
 * Conjunct4
 * Tournament api for connect 4.
 *
 * The version of the OpenAPI document: 0.2.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GetApiVersion200Response,
  LogRequest,
} from '../models';
import {
    GetApiVersion200ResponseFromJSON,
    GetApiVersion200ResponseToJSON,
    LogRequestFromJSON,
    LogRequestToJSON,
} from '../models';

export interface LogOperationRequest {
    logRequest: LogRequest;
}

/**
 * 
 */
export class MiscApi extends runtime.BaseAPI {

    /**
     * Get current backend build and version.
     * Get version.
     */
    async getApiVersionRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetApiVersion200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/version`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetApiVersion200ResponseFromJSON(jsonValue));
    }

    /**
     * Get current backend build and version.
     * Get version.
     */
    async getApiVersion(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetApiVersion200Response> {
        const response = await this.getApiVersionRaw(initOverrides);
        return await response.value();
    }

    /**
     * Push a single structured log event to be logged on the backend. Properties should be named using PascalCase. There is a number of already used and defined properties that should be only used in an expected way, for the ease of querying the logs:   `User`: A discord snowflake id of a user.   `Round`: Round id.   `Game`: Game id.   `Bracket`: Bracket id.   `Tournament`: Tournament id.   `SourceContext`: Source of the log event, examples: `\"Microsoft.AspNetCore.Hosting.Diagnostics\"`, `\"Conjunct4API.Workers.TimerWorker\"`.   For the events received with this api will be prefixed with `\"Conjunct4Client.\"` if defined, or will be set to `\"Conjunct4Client\"`  if not defined in this request.  Properties that should not be used: `EventId`, `Host`, `EndpointName`, `ActionId`, `ActionName`, `RequestId`, `RequestPath`, `ConnectionId`, `RouteData`, `MethodInfo`, `Controller`, `AssemblyName`. 
     * Log an event
     */
    async logRaw(requestParameters: LogOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.logRequest === null || requestParameters.logRequest === undefined) {
            throw new runtime.RequiredError('logRequest','Required parameter requestParameters.logRequest was null or undefined when calling log.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/log`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LogRequestToJSON(requestParameters.logRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Push a single structured log event to be logged on the backend. Properties should be named using PascalCase. There is a number of already used and defined properties that should be only used in an expected way, for the ease of querying the logs:   `User`: A discord snowflake id of a user.   `Round`: Round id.   `Game`: Game id.   `Bracket`: Bracket id.   `Tournament`: Tournament id.   `SourceContext`: Source of the log event, examples: `\"Microsoft.AspNetCore.Hosting.Diagnostics\"`, `\"Conjunct4API.Workers.TimerWorker\"`.   For the events received with this api will be prefixed with `\"Conjunct4Client.\"` if defined, or will be set to `\"Conjunct4Client\"`  if not defined in this request.  Properties that should not be used: `EventId`, `Host`, `EndpointName`, `ActionId`, `ActionName`, `RequestId`, `RequestPath`, `ConnectionId`, `RouteData`, `MethodInfo`, `Controller`, `AssemblyName`. 
     * Log an event
     */
    async log(requestParameters: LogOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.logRaw(requestParameters, initOverrides);
    }

}

/* tslint:disable */
/* eslint-disable */
/**
 * Conjunct4 Test
 * Testing api for the Conjunct4 Tournament api.
 *
 * The version of the OpenAPI document: 0.1.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateGameRequest
 */
export interface CreateGameRequest {
    /**
     * Opponents discord id. If the id was never logged in to on the api server,
     * creates a dummy user that auto-accepts games the player also accepted.
     * It is allowed to request the dummy user info from `/v1/user/{id}`.
     * 
     * If an actual user with the same id as the dummy user logs in, the dummy
     * user ceases to be a dummy user, the actual user takes its place completely.
     * @type {string}
     * @memberof CreateGameRequest
     */
    opponentId: string;
    /**
     * Time in seconds before the game starts.
     * @type {number}
     * @memberof CreateGameRequest
     */
    startIn?: number;
    /**
     * Amount of wins required.
     * @type {number}
     * @memberof CreateGameRequest
     */
    requiredWins?: number;
    /**
     * Total time in seconds of normal rounds. Player timers = roundTime / 2.
     * @type {number}
     * @memberof CreateGameRequest
     */
    roundTime?: number;
    /**
     * Time in seconds after one round finished before the next one starts.
     * @type {number}
     * @memberof CreateGameRequest
     */
    cooloffTime?: number;
    /**
     * Time in seconds to accept the game.
     * @type {number}
     * @memberof CreateGameRequest
     */
    acceptTime?: number;
    /**
     * Time multiplier for extra rounds in case of draw. 
     * Should be between 0 and 1 but can be more than one, 
     * increasing the round time for the extra rounds.
     * @type {number}
     * @memberof CreateGameRequest
     */
    extraRoundMultiplier?: number;
}

/**
 * Check if a given object implements the CreateGameRequest interface.
 */
export function instanceOfCreateGameRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "opponentId" in value;

    return isInstance;
}

export function CreateGameRequestFromJSON(json: any): CreateGameRequest {
    return CreateGameRequestFromJSONTyped(json, false);
}

export function CreateGameRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateGameRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'opponentId': json['opponentId'],
        'startIn': !exists(json, 'startIn') ? undefined : json['startIn'],
        'requiredWins': !exists(json, 'requiredWins') ? undefined : json['requiredWins'],
        'roundTime': !exists(json, 'roundTime') ? undefined : json['roundTime'],
        'cooloffTime': !exists(json, 'cooloffTime') ? undefined : json['cooloffTime'],
        'acceptTime': !exists(json, 'acceptTime') ? undefined : json['acceptTime'],
        'extraRoundMultiplier': !exists(json, 'extraRoundMultiplier') ? undefined : json['extraRoundMultiplier'],
    };
}

export function CreateGameRequestToJSON(value?: CreateGameRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'opponentId': value.opponentId,
        'startIn': value.startIn,
        'requiredWins': value.requiredWins,
        'roundTime': value.roundTime,
        'cooloffTime': value.cooloffTime,
        'acceptTime': value.acceptTime,
        'extraRoundMultiplier': value.extraRoundMultiplier,
    };
}


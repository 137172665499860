import { Image } from "react-bootstrap"
import { User } from "services"
import Loading from "./Loading"
import style from './UserPanel.module.scss'
export interface UserPanelProps {
    user? : User,
    truncate? : boolean,
}

export default function UserPanel({ user, truncate = false } : UserPanelProps) {

    function renderUser() {
        if (!user) return;

        let userName = user.display ?? user.username;
        if (userName.length >= 20 && truncate) {
            userName = userName.substring(0, 20) + "...";
        }

        return <div className={style.userPanel}>
            <Image width="48" rounded fluid src={user.avatar} /> {userName} <small className="text-muted">#{user.discriminator}</small>
        </div>
    }

    function renderNone() {
        if (user) return;
        return <Loading/>;
    }

    return <>
        {renderUser()}
        {renderNone()}
    </>
}
import { Toast, ToastContainer } from 'react-bootstrap';
import React, { useCallback, useState, useContext } from "react";
import { useInterval } from 'usehooks-ts';

const PBToastContext = React.createContext(null);
let id = 1;

const PBToastProvider = ({children}) => {
    const [toasts, setToasts] = useState([]);

    // Calculate TTL
    useInterval(() => {
        const newToasts = toasts.map(toast => {
            toast = { ...toast };
            toast.ttl -= 100;

            if ( toast.ttlStart - toast.ttl <= 500 ) {
                toast.show = false;
            } else {
                toast.show = true;
            }

            return toast;
        });

        if ( newToasts.length > 0 ) {
            setToasts(newToasts);
        }

        newToasts.forEach(toast => {
            if (toast.ttl <= 0) {
                removeToast(toast.id);
            }
        });
    }, 100);

    // Add a toast... duh.
    const addToast = useCallback(
        (title, content, ttl = 3500) => {
            setToasts(toasts => [
                ...toasts,
                {
                    id: id++,
                    title,
                    content,
                    ttl,
                    ttlStart: ttl,
                    show : false
                }
            ]);
        },
        [setToasts]
    );

    const addToastCustomColor = useCallback(
        (title, content, color, ttl = 3500) => {
            setToasts(toasts => [
                ...toasts,
                {
                    id: id++,
                    title,
                    content,
                    ttl,
                    ttlStart: ttl,
                    show : false,
                    color : color
                }
            ]);
        },
        [setToasts]
    );

    // Remove a toast
    const removeToast = useCallback(
        id => {
            setToasts(toasts => toasts.filter(t => t.id !== id));
        },
        [setToasts]
    );


    return (
        <PBToastContext.Provider
          value={{
            addToast,
            removeToast,
            addToastCustomColor
          }}
        >
            <ToastContainer style={{zIndex: "2000"}} position="top-center">
                {toasts.map(toast => {
                    return <Toast bg="light" show={toast.show} key={toast.id} onClose={() => removeToast(toast.id)}>
                        <Toast.Header closeButton={false}>
                            <strong className="me-auto">{toast.title}</strong> <small className="text-muted">just now</small>   
                        </Toast.Header>
                        <Toast.Body style={{backgroundColor: (toast.color || "#f0ad4e"), color: "#fff"}}>
                            {toast.content}
                        </Toast.Body>
                    </Toast>
                })}
            </ToastContainer>
            {children}
        </PBToastContext.Provider>
      );
}

function usePBToast() {
    const toastHelpers = useContext(PBToastContext);
    return toastHelpers;
}

export { PBToastContext, usePBToast };
export default PBToastProvider;
import { lazy } from 'react'
import ControlPanelLayout from './layouts/ControlPanelLayout';
import React from 'react';

type LazyLoadedComponent = React.LazyExoticComponent<React.ComponentType<any>>;

export type RouteParams = {
  disable_redirect?: boolean,
  always_scroll?: boolean
} 

export interface Route {
  key : string,
  path : string,
  component : LazyLoadedComponent|React.FC<any>,
  layout : LazyLoadedComponent|React.FC<any>,
  title : string,

  showInNav?: boolean,
  params?: RouteParams
}

export const routeKeys = {
  LOGIN             : "login",
  LOGIN_DEFAULT     : "login_default",
  HOME              : "home",
  PREVIOUS_MATCHES  : "previous_matches",
  GAME              : "game",
  GAME_REPLAY       : "game_replay",
  TOURNAMENTS       : "tournaments",
  SETTINGS          : "settings",
  FALLBACK          : "fallback"
};

const routes : Route[] = [
  {
    key : routeKeys.LOGIN,
    path : "/login",
    component : lazy(() => import( 'pages/LoginPage' )),
    layout : lazy(() => import( './layouts/LoginLayout' )),
    title : "page.login.title"
  },
  {
    key : routeKeys.LOGIN_DEFAULT,
    path : "/",
    component : lazy(() => import( 'pages/LoginPage' )),
    layout : lazy(() => import( './layouts/LoginLayout' )),
    title : "page.login.title"
  },

  {
    key : routeKeys.HOME,
    path : "/home",
    component : lazy(() => import( 'pages/HomePage' )),
    layout : ControlPanelLayout,
    title : "page.home.title",

    showInNav : true,
  },

  {
    key : routeKeys.PREVIOUS_MATCHES,
    path : "/previous",
    component : lazy(() => import( 'pages/PreviousMatchesPage' )),
    layout : ControlPanelLayout,
    title : "page.previous.title",

    showInNav : true,
  },

  {
    key : routeKeys.GAME,
    path : "/round/:id",
    component : lazy(() => import( 'pages/GamePage' )),
    layout : ControlPanelLayout,
    title : "page.game.title",

    showInNav : false,
    params: {
      always_scroll : true
    }
  },
  {
    key : routeKeys.GAME_REPLAY,
    path : "/round_replay/:id",
    component : lazy(() => import( 'pages/GamePage' )),
    layout : ControlPanelLayout,
    title : "page.game.title",
    params: {
      always_scroll : true
    },
    showInNav : false,
  },
/*
  {
    key : routeKeys.TOURNAMENTS,
    path : "/tournaments",
    component : lazy(() => import( 'pages/TournamentPage' )),
    layout : ControlPanelLayout,
    title : "page.tournaments.title",

    showInNav : true,
  },
*/
  {
    key : routeKeys.SETTINGS,
    path : "/settings",
    component : lazy(() => import( 'pages/Settings' )),
    layout : ControlPanelLayout,
    title : "page.settings.title",

    showInNav : true,
  },

  {
    key : routeKeys.FALLBACK,
    path : "*",
    component : lazy(() => import( 'pages/NotFoundPage' )),
    layout : lazy(() => import( './layouts/LoginLayout' )),
    title : "page.404.title",
    params : {
      disable_redirect : true
    }
  }
];

const _routes : Record<string, Route> = {};
for (const route of routes) {
  _routes[route.key] = route;
}

export function findRoute(key : string) : Route {
  if ( !_routes[key] ) {
    throw new Error(`Route not found: ${key}`);
  } 

  return _routes[key];
}

export function parameterizePath(route : Route, params : Record<string, string>) : string {
  let path = route.path;

  Object.keys(params).forEach(urlParamName => {
    const urlParamValue = params[urlParamName];
    path = path.replace(`:${urlParamName}`, urlParamValue);
  });

  return path;
}

export default routes;
/* tslint:disable */
/* eslint-disable */
/**
 * Conjunct4
 * Tournament api for connect 4.
 *
 * The version of the OpenAPI document: 0.2.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Game,
  GameError,
} from '../models';
import {
    GameFromJSON,
    GameToJSON,
    GameErrorFromJSON,
    GameErrorToJSON,
} from '../models';

export interface ForfeitGameRequest {
    gameid: string;
}

export interface GetGameInfoRequest {
    gameid: string;
}

/**
 * 
 */
export class MatchApi extends runtime.BaseAPI {

    /**
     * Create an open game. Anyone can join the open game by requesting the game with the `/game/{gameid}` handle. 
     * Create open game.
     */
    async createOpenGameRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Game>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/opengame`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GameFromJSON(jsonValue));
    }

    /**
     * Create an open game. Anyone can join the open game by requesting the game with the `/game/{gameid}` handle. 
     * Create open game.
     */
    async createOpenGame(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Game> {
        const response = await this.createOpenGameRaw(initOverrides);
        return await response.value();
    }

    /**
     * Attempt to give up the game.
     * Give up the game.
     */
    async forfeitGameRaw(requestParameters: ForfeitGameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.gameid === null || requestParameters.gameid === undefined) {
            throw new runtime.RequiredError('gameid','Required parameter requestParameters.gameid was null or undefined when calling forfeitGame.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/game/{gameid}`.replace(`{${"gameid"}}`, encodeURIComponent(String(requestParameters.gameid))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Attempt to give up the game.
     * Give up the game.
     */
    async forfeitGame(requestParameters: ForfeitGameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.forfeitGameRaw(requestParameters, initOverrides);
    }

    /**
     * Get the information about the game. If the game is an open game, and doesn\'t have the second player yet, joins the game as the second player. After this the game becomes a normal game between the player and the user who created the open game. 
     * Get game info.
     */
    async getGameInfoRaw(requestParameters: GetGameInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Game>> {
        if (requestParameters.gameid === null || requestParameters.gameid === undefined) {
            throw new runtime.RequiredError('gameid','Required parameter requestParameters.gameid was null or undefined when calling getGameInfo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/game/{gameid}`.replace(`{${"gameid"}}`, encodeURIComponent(String(requestParameters.gameid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GameFromJSON(jsonValue));
    }

    /**
     * Get the information about the game. If the game is an open game, and doesn\'t have the second player yet, joins the game as the second player. After this the game becomes a normal game between the player and the user who created the open game. 
     * Get game info.
     */
    async getGameInfo(requestParameters: GetGameInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Game> {
        const response = await this.getGameInfoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the list of the games for the player.
     * Get upcoming games.
     */
    async getGamesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Game>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/game`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GameFromJSON));
    }

    /**
     * Get the list of the games for the player.
     * Get upcoming games.
     */
    async getGames(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Game>> {
        const response = await this.getGamesRaw(initOverrides);
        return await response.value();
    }

}

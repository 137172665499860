/* tslint:disable */
/* eslint-disable */
/**
 * Conjunct4
 * Tournament api for connect 4.
 *
 * The version of the OpenAPI document: 0.2.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Initial timers of the players
 * @export
 * @interface RoundAllOfHistoryTimers
 */
export interface RoundAllOfHistoryTimers {
    /**
     * Current player's initial timer in seconds
     * @type {number}
     * @memberof RoundAllOfHistoryTimers
     */
    player: number;
    /**
     * Opponent's initial timer in seconds
     * @type {number}
     * @memberof RoundAllOfHistoryTimers
     */
    enemy: number;
}

/**
 * Check if a given object implements the RoundAllOfHistoryTimers interface.
 */
export function instanceOfRoundAllOfHistoryTimers(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "player" in value;
    isInstance = isInstance && "enemy" in value;

    return isInstance;
}

export function RoundAllOfHistoryTimersFromJSON(json: any): RoundAllOfHistoryTimers {
    return RoundAllOfHistoryTimersFromJSONTyped(json, false);
}

export function RoundAllOfHistoryTimersFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoundAllOfHistoryTimers {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'player': json['player'],
        'enemy': json['enemy'],
    };
}

export function RoundAllOfHistoryTimersToJSON(value?: RoundAllOfHistoryTimers | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'player': value.player,
        'enemy': value.enemy,
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Conjunct4
 * Tournament api for connect 4.
 *
 * The version of the OpenAPI document: 0.2.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ModifyRoundBoardStateRequest
 */
export interface ModifyRoundBoardStateRequest {
    /**
     * A column to make a move in.
     * @type {number}
     * @memberof ModifyRoundBoardStateRequest
     */
    column: number;
}

/**
 * Check if a given object implements the ModifyRoundBoardStateRequest interface.
 */
export function instanceOfModifyRoundBoardStateRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "column" in value;

    return isInstance;
}

export function ModifyRoundBoardStateRequestFromJSON(json: any): ModifyRoundBoardStateRequest {
    return ModifyRoundBoardStateRequestFromJSONTyped(json, false);
}

export function ModifyRoundBoardStateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModifyRoundBoardStateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'column': json['column'],
    };
}

export function ModifyRoundBoardStateRequestToJSON(value?: ModifyRoundBoardStateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'column': value.column,
    };
}


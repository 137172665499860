const en = {
    'lang.title': 'English',
    
    'page.settings.title': 'Settings',
    'page.settings.language': 'Selected language',

    'page.game.title': 'Game',
    'page.game.giveUp': 'Give up',
    'page.game.giveUp.confirm': 'Are you sure you want to give up?',

    'page.home.title': 'Upcoming Matches',

    'page.previous.title': 'Previous Matches',

    'page.login.title': 'Login',
    'page.login.subtitle': 'Conjunct4 Tournament',
    'page.login.line_0': 'This website automatically creates Connect4 tournaments that you can play in.',
    'page.login.line_1.seg_0': 'You must be a member of',
    'page.login.line_1.seg_1': 'Achimaru\'s Server',
    'page.login.line_1.seg_2': 'to login',

    'page.offline.title': 'Offline',
    'page.offline.text': 'You are currently offline. Please check your internet connection and try again.',

    'page.tournaments.title': 'Tournaments',

    'page.404.title': '404 - File Not Found',
    'page.404.text': 'The page you are looking for does not exist.',

    'component.gametable.no_games': 'There are no games to display.',
    'component.gametable.error': ({message}) => `Something went wrong while trying to load the games: ${message}`,
    
    'component.gamecard.round_title' : "Rounds",
    'component.gamecard.state.concluded' : "concluded",
    'component.gamecard.state.ongoing' : "ongoing",
    'component.gamecard.eta.ahead' : ({ time }) => `Match starting in ${time}`,
    'component.gamecard.eta.behind' : ({ time }) => `Match started ${time} ago`,
    'component.gamecard.playbutton.accept' : 'Accept Game',
    'component.gamecard.playbutton.rejoin' : 'Rejoin Game',

    'component.roundtable.state.planned': 'Planned',
    'component.roundtable.state.starting': 'Starting',
    'component.roundtable.state.playing': 'Playing',
    'component.roundtable.state.finished': 'Finished',
    'component.roundtable.state.unknown': 'Unknown',
    'component.roundtable.result.win_self': 'Win',
    'component.roundtable.result.win_other': 'Loss',
    'component.roundtable.result.draw': 'Draw',
    'component.roundtable.result.unknown': 'None',
    'component.roundtable.result.time_self': 'Time',
    'component.roundtable.result.time_other': 'Time',
    'component.roundtable.result.dq_self': 'Disqualified',
    'component.roundtable.result.dq_other': 'Disqualified',
    'component.roundtable.status': 'Status',
    'component.roundtable.result': 'Result',
    'component.roundtable.time': 'Time',
    'component.roundtable.ready_notification': "A match is ready to be played!",
    'component.roundtable.play_button.play': "Play",
    'component.roundtable.play_button.view': "View Board",

    'component.tournamentview.state': 'State',
    'component.tournamentview.winner': 'Winner',
    'component.tournamentview.state.inprogress': 'In Progress',
    'component.tournamentview.state.finished': 'Finished',

    'component.confirmmodal.cancel': 'Cancel',
    'component.confirmmodal.confirm': 'Confirm',

    'component.tournamentselector.pleaseSelect': 'Please select a tournament',
    'component.tournamentselector.noTournaments': 'No tournaments found',

    'component.connect4replaycontrols.move.player': 'Player: ',
    'component.connect4replaycontrols.move.opponent': 'Opponent: ',
    'component.connect4replaycontrols.start.player': 'Start (Your turn)',
    'component.connect4replaycontrols.start.opponent': 'Start (Opponent turn)',
    'component.connect4replaycontrols.turn.player': 'Your turn',
    'component.connect4replaycontrols.turn.opponent': 'Opponent turn',

    'button.login': 'Connect with Discord!',
    'button.language': 'English',
    'button.language.en': 'English',
    'button.language.jp': 'Japanese',
    'button.logout': 'Logout',
    'button.back': 'Go Back'
}

export type TranslationKey = keyof typeof en;
export type TranslationParams = Record<string, string>;
export type TranslatorFunction = (params: TranslationParams) => string;
export type TranslatorDictionary = Record<TranslationKey, string|TranslatorFunction>;

const jp : TranslatorDictionary = {
    'lang.title': '日本語',
    
    'page.settings.title': 'セッティング',
    'page.settings.language': '選択言語',

    'page.game.title': 'ゲーム',
    'page.game.giveUp': '諦める',
    'page.game.giveUp.confirm': '本当に諦めりますか？',

    'page.home.title': '新しい試合',

    'page.previous.title': '過去のゲーム',

    'page.login.title': 'ログイン',
    'page.login.subtitle': '「Conjunct4」コネクトフォー大会',
    'page.login.line_0': 'このサイトはあなたがプレイできるコネクトフォー大会を自動的に作っています。',
    'page.login.line_1.seg_0': '',
    'page.login.line_1.seg_1': 'ACHIMARUのサーバー',
    'page.login.line_1.seg_2': 'の一員しかログインできません。',

    'page.offline.title': 'オッフラ犬',
    'page.offline.text': '回線が切れされました。回線の設定をチェックして、再度を試しください。',

    'page.tournaments.title': '大会',

    'page.404.title': '404 - File Not Found',
    'page.404.text': '要求されたページが存在していません。',

    'component.gametable.no_games': '示すできるゲームがありません。',
    'component.gametable.error': ({message}) => `Something went wrong while trying to load the games: ${message}`,
    
    'component.gamecard.round_title' : "ラウンド",
    'component.gamecard.state.concluded' : "仕上がった",
    'component.gamecard.state.ongoing' : "現在プレイ中",
    'component.gamecard.eta.ahead' : ({ time }) => `ゲームは${time}後に始まります`,
    'component.gamecard.eta.behind' : ({ time }) => `ゲームは${time}前に始めました`,
    'component.gamecard.playbutton.accept' : 'ゲームを受け入れる',
    'component.gamecard.playbutton.rejoin' : 'ゲームに再参加する',

    'component.roundtable.state.planned': '未来',
    'component.roundtable.state.starting': '始まっています',
    'component.roundtable.state.playing': 'プレイ中',
    'component.roundtable.state.finished': '仕上がった',
    'component.roundtable.state.unknown': '不明',
    'component.roundtable.result.win_self': '勝ち',
    'component.roundtable.result.win_other': '負け',
    'component.roundtable.result.draw': '引き分け',
    'component.roundtable.result.unknown': 'ない',
    'component.roundtable.result.time_self': '時間切れ',
    'component.roundtable.result.time_other': '時間切れ',
    'component.roundtable.result.dq_self': '失格',
    'component.roundtable.result.dq_other': '失格',
    'component.roundtable.status': 'ステータス',
    'component.roundtable.result': '結果',
    'component.roundtable.time': '時間',
    'component.roundtable.ready_notification': "試合は準備されました",
    'component.roundtable.play_button.play': "試合を入る",
    'component.roundtable.play_button.view': "見る",

    'component.tournamentview.state': 'ステータス',
    'component.tournamentview.winner': '照射',
    'component.tournamentview.state.inprogress': '進行中',
    'component.tournamentview.state.finished': '終了しました',

    'component.confirmmodal.cancel': 'キャンセルする',
    'component.confirmmodal.confirm': '確認する',

    'component.tournamentselector.pleaseSelect': '大会を選んでください',
    'component.tournamentselector.noTournaments': '大会がありません',

    'component.connect4replaycontrols.move.player': 'プレイヤー：',
    'component.connect4replaycontrols.move.opponent': '挑戦者：',    
    'component.connect4replaycontrols.start.player': '初め (プレイヤーの順番)',
    'component.connect4replaycontrols.start.opponent': '初め (挑戦者の順番)',
    'component.connect4replaycontrols.turn.player': 'プレイヤーの順番',
    'component.connect4replaycontrols.turn.opponent': '挑戦者の順番',
    
    'button.login': 'Discordでログイン!',
    'button.language': '日本語',
    'button.language.en': '英語',
    'button.language.jp': '日本語',
    'button.logout': 'ログアウト',
    'button.back': '戻る'
}

const _en = en as unknown as TranslatorDictionary;
const lang : Record<string, TranslatorDictionary> = {
    en : _en,
    jp
}

export type LanguageEnum = 'en'|'jp';

export function i18n(locale : LanguageEnum, identifier : TranslationKey, args : TranslationParams = {}) {
    if (!lang[locale] || (!lang[locale][identifier] && lang[locale][identifier] !== '')) {
        return `${locale}|${identifier}`;
    }

    const value = lang[locale][identifier];

    if (value instanceof Function) {
        return value(args);
    } else {
        return value;
    }
}
/* tslint:disable */
/* eslint-disable */
/**
 * Conjunct4
 * Tournament api for connect 4.
 *
 * The version of the OpenAPI document: 0.2.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LogRequest
 */
export interface LogRequest {
    /**
     * Log message template in the following format: `Something happened with the user {User} and game {Game}` The properties used in the template must be provided in the meta property of the request.
     * @type {string}
     * @memberof LogRequest
     */
    template: string;
    /**
     * Log level, by default `debug` level is not logged, but can be enabled in the api configuration. The levels are treated in the same way as the backend levels, thus using levels above `warning` is not recommended unless absolutely necessary.
     * @type {string}
     * @memberof LogRequest
     */
    level?: LogRequestLevelEnum;
    /**
     * Additional context named properties of the log event. Can specify any amount, but at least those used in the template must be provided. Should use PascalCase for the property names.
     * @type {{ [key: string]: any; }}
     * @memberof LogRequest
     */
    meta?: { [key: string]: any; };
}


/**
 * @export
 */
export const LogRequestLevelEnum = {
    Debug: 'debug',
    Information: 'information',
    Warning: 'warning',
    Error: 'error',
    Critical: 'critical'
} as const;
export type LogRequestLevelEnum = typeof LogRequestLevelEnum[keyof typeof LogRequestLevelEnum];


/**
 * Check if a given object implements the LogRequest interface.
 */
export function instanceOfLogRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "template" in value;

    return isInstance;
}

export function LogRequestFromJSON(json: any): LogRequest {
    return LogRequestFromJSONTyped(json, false);
}

export function LogRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): LogRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'template': json['template'],
        'level': !exists(json, 'level') ? undefined : json['level'],
        'meta': !exists(json, 'meta') ? undefined : json['meta'],
    };
}

export function LogRequestToJSON(value?: LogRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'template': value.template,
        'level': value.level,
        'meta': value.meta,
    };
}


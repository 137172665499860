import { Component, ErrorInfo } from "react";
import { Card } from "react-bootstrap";
import { apis, LogRequestLevelEnum } from "services";
import AlertModal from "components/AlertModal";
import Loading from "./Loading";

export type ErrorBoundaryProps = {
    children: React.ReactNode;
    userId?: string;
};

export type ErrorBoundaryState = {
    error?: Error;
};

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props) {
        super(props);
        this.state = {
            error: null
        };
    }
    
    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { error };
    }

    logErrorToMyService(error : Error, errorInfo : ErrorInfo) {
        apis.misc.log({
            logRequest : {
                template: "User {User} encountered in error boundary: {ClientError.name} - {ClientError.message}",
                level: LogRequestLevelEnum.Critical,
                meta: {
                    User: this.props.userId ?? null,
                    ClientError: {
                        name: error.name,
                        message: error.message,
                        stack: error.stack,
                        componentstack: errorInfo.componentStack
                    }
                }
            }
        })
    }
    
    componentDidCatch(error, errorInfo) {
        // logErrorToMyService(error, errorInfo);
        this.logErrorToMyService(error, errorInfo);

        window.setTimeout(() => {
            window.location.reload();
        }, 5000);
    }
    
    render() {
        if (this.state.error) {
            // You can render any custom fallback UI
            return <AlertModal show={true} title={"Something went wrong."}>
                {/*
                {this.state.error.name} - {this.state.error.message}<br />
                <pre>{this.state.error.stack}</pre>
                */}
                This page will report the error automatically and reload momentarily.<br />
                <Loading className="mt-2" loading={true} />
                <div className="text-center">Please wait</div>
            </AlertModal>;
        }

        return this.props.children; 
    }
}
/* tslint:disable */
/* eslint-disable */
/**
 * Conjunct4
 * Tournament api for connect 4.
 *
 * The version of the OpenAPI document: 0.2.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * User.
 * @export
 * @interface User
 */
export interface User {
    /**
     * Discord user id (snowflake).
     * @type {string}
     * @memberof User
     */
    id: string;
    /**
     * Display name of the user in the guild the tournament in context was  organized from.
     * @type {string}
     * @memberof User
     */
    display?: string;
    /**
     * Discord username.
     * @type {string}
     * @memberof User
     */
    username: string;
    /**
     * Discord discriminator.
     * @type {number}
     * @memberof User
     */
    discriminator: number;
    /**
     * Profile picture uri of the guild identity of the discord user if exists, global profile picture otherwise.
     * @type {string}
     * @memberof User
     */
    avatar?: string;
}

/**
 * Check if a given object implements the User interface.
 */
export function instanceOfUser(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "username" in value;
    isInstance = isInstance && "discriminator" in value;

    return isInstance;
}

export function UserFromJSON(json: any): User {
    return UserFromJSONTyped(json, false);
}

export function UserFromJSONTyped(json: any, ignoreDiscriminator: boolean): User {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'display': !exists(json, 'display') ? undefined : json['display'],
        'username': json['username'],
        'discriminator': json['discriminator'],
        'avatar': !exists(json, 'avatar') ? undefined : json['avatar'],
    };
}

export function UserToJSON(value?: User | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'display': value.display,
        'username': value.username,
        'discriminator': value.discriminator,
        'avatar': value.avatar,
    };
}


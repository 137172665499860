/* tslint:disable */
/* eslint-disable */
/**
 * Conjunct4
 * Tournament api for connect 4.
 *
 * The version of the OpenAPI document: 0.2.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RoundAllOfHistoryMoves } from './RoundAllOfHistoryMoves';
import {
    RoundAllOfHistoryMovesFromJSON,
    RoundAllOfHistoryMovesFromJSONTyped,
    RoundAllOfHistoryMovesToJSON,
} from './RoundAllOfHistoryMoves';
import type { RoundAllOfHistoryTimers } from './RoundAllOfHistoryTimers';
import {
    RoundAllOfHistoryTimersFromJSON,
    RoundAllOfHistoryTimersFromJSONTyped,
    RoundAllOfHistoryTimersToJSON,
} from './RoundAllOfHistoryTimers';

/**
 * Move history. Might not be returned.
 * @export
 * @interface RoundAllOfHistory
 */
export interface RoundAllOfHistory {
    /**
     * First turn, player or enemy.
     * @type {string}
     * @memberof RoundAllOfHistory
     */
    first: RoundAllOfHistoryFirstEnum;
    /**
     * A list of moves happened in the round. Ordered from old to  new (first move is at index 0).
     * @type {Array<RoundAllOfHistoryMoves>}
     * @memberof RoundAllOfHistory
     */
    moves: Array<RoundAllOfHistoryMoves>;
    /**
     * 
     * @type {RoundAllOfHistoryTimers}
     * @memberof RoundAllOfHistory
     */
    timers: RoundAllOfHistoryTimers;
}


/**
 * @export
 */
export const RoundAllOfHistoryFirstEnum = {
    Player: 'player',
    Enemy: 'enemy'
} as const;
export type RoundAllOfHistoryFirstEnum = typeof RoundAllOfHistoryFirstEnum[keyof typeof RoundAllOfHistoryFirstEnum];


/**
 * Check if a given object implements the RoundAllOfHistory interface.
 */
export function instanceOfRoundAllOfHistory(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "first" in value;
    isInstance = isInstance && "moves" in value;
    isInstance = isInstance && "timers" in value;

    return isInstance;
}

export function RoundAllOfHistoryFromJSON(json: any): RoundAllOfHistory {
    return RoundAllOfHistoryFromJSONTyped(json, false);
}

export function RoundAllOfHistoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoundAllOfHistory {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'first': json['first'],
        'moves': ((json['moves'] as Array<any>).map(RoundAllOfHistoryMovesFromJSON)),
        'timers': RoundAllOfHistoryTimersFromJSON(json['timers']),
    };
}

export function RoundAllOfHistoryToJSON(value?: RoundAllOfHistory | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'first': value.first,
        'moves': ((value.moves as Array<any>).map(RoundAllOfHistoryMovesToJSON)),
        'timers': RoundAllOfHistoryTimersToJSON(value.timers),
    };
}


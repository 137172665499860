/* tslint:disable */
/* eslint-disable */
/**
 * Conjunct4
 * Tournament api for connect 4.
 *
 * The version of the OpenAPI document: 0.2.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Version information.
 * @export
 * @interface GetApiVersion200Response
 */
export interface GetApiVersion200Response {
    /**
     * Conjunct4Api version supported by the current backend  build.
     * @type {string}
     * @memberof GetApiVersion200Response
     */
    apiVersion?: string;
    /**
     * Backend build number.
     * @type {string}
     * @memberof GetApiVersion200Response
     */
    build?: string;
}

/**
 * Check if a given object implements the GetApiVersion200Response interface.
 */
export function instanceOfGetApiVersion200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GetApiVersion200ResponseFromJSON(json: any): GetApiVersion200Response {
    return GetApiVersion200ResponseFromJSONTyped(json, false);
}

export function GetApiVersion200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetApiVersion200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'apiVersion': !exists(json, 'apiVersion') ? undefined : json['apiVersion'],
        'build': !exists(json, 'build') ? undefined : json['build'],
    };
}

export function GetApiVersion200ResponseToJSON(value?: GetApiVersion200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'apiVersion': value.apiVersion,
        'build': value.build,
    };
}


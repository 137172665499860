/* tslint:disable */
/* eslint-disable */
/**
 * Conjunct4
 * Tournament api for connect 4.
 *
 * The version of the OpenAPI document: 0.2.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Bracket } from './Bracket';
import {
    BracketFromJSON,
    BracketFromJSONTyped,
    BracketToJSON,
} from './Bracket';
import type { TournamentAllOf } from './TournamentAllOf';
import {
    TournamentAllOfFromJSON,
    TournamentAllOfFromJSONTyped,
    TournamentAllOfToJSON,
} from './TournamentAllOf';
import type { TournamentHeader } from './TournamentHeader';
import {
    TournamentHeaderFromJSON,
    TournamentHeaderFromJSONTyped,
    TournamentHeaderToJSON,
} from './TournamentHeader';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface Tournament
 */
export interface Tournament {
    /**
     * Id of the tournament.
     * @type {string}
     * @memberof Tournament
     */
    id: string;
    /**
     * Name of the tournament.
     * @type {string}
     * @memberof Tournament
     */
    name: string;
    /**
     * Status of the tournament.
     * @type {string}
     * @memberof Tournament
     */
    status: TournamentStatusEnum;
    /**
     * 
     * @type {User}
     * @memberof Tournament
     */
    winner?: User;
    /**
     * 
     * @type {Bracket}
     * @memberof Tournament
     */
    root: Bracket;
}


/**
 * @export
 */
export const TournamentStatusEnum = {
    InProgress: 'inProgress',
    Finished: 'finished'
} as const;
export type TournamentStatusEnum = typeof TournamentStatusEnum[keyof typeof TournamentStatusEnum];


/**
 * Check if a given object implements the Tournament interface.
 */
export function instanceOfTournament(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "root" in value;

    return isInstance;
}

export function TournamentFromJSON(json: any): Tournament {
    return TournamentFromJSONTyped(json, false);
}

export function TournamentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Tournament {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'status': json['status'],
        'winner': !exists(json, 'winner') ? undefined : UserFromJSON(json['winner']),
        'root': BracketFromJSON(json['root']),
    };
}

export function TournamentToJSON(value?: Tournament | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'status': value.status,
        'winner': UserToJSON(value.winner),
        'root': BracketToJSON(value.root),
    };
}


import { Container, Row, Col, Card } from "react-bootstrap";
import { PageComponentProps } from "./PageComponent";
import { i18n, TranslationKey, LanguageEnum } from "services";
import { observer } from "mobx-react-lite";
import { useStore } from "MST/StoreProvider";

export default observer(function OfflinePage() {
    const store = useStore();
    const lang = store.language as LanguageEnum;

    return <Container>
        <Row>
            <Col>
                <Card style={{marginTop: "16px"}}>
                    <Card.Body>
                        <Card.Title>{i18n(lang, 'page.offline.title')}</Card.Title>
                        <hr />
                        {i18n(lang, 'page.offline.text')}
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </Container>
});
import React from "react";
import {
    Container,
    Row,
    Col,
    Image,
} from "react-bootstrap";
import { RouteParams, Route } from "routes";

import style from './LoginLayout.module.scss';
import banner from 'assets/images/banner.png';

type LoginLayoutProps = {
    children: React.ReactNode;
    params : RouteParams;
    route : Route;
}

const LoginLayout : React.FC<LoginLayoutProps> = ({children, params = {}, route}) => {
    return (<>
        <Container fluid style={{paddingLeft: "0px"}}>
            <Row>
                <Col md={6} lg={5} xl={5} xxl={4} style={{paddingRight: "0px"}}>
                    <div className={`${style.login} layout-content`}>
                        <Image src={banner} alt="banner" className={`${style['login-banner']} img-fluid`} />
                        <div className={`${style['login-body']}`}>
                            {children}
                        </div>
                    </div>
                </Col>
                <Col style={{paddingLeft: "0px", paddingRight: "0px"}}>
                    <div className={style.background}></div>
                </Col>
            </Row>
        </Container>
    </>);
}

export default LoginLayout;
import { useLocalObservable } from "mobx-react-lite";
import rootStore, { RootStoreModel } from './root';
import React from 'react';

const storeContext = React.createContext<RootStoreModel>(null);

export interface StoreProviderProps {
    children : React.ReactNode;
}

export const StoreProvider : React.FC<StoreProviderProps> = ({children}) => {
    const store = useLocalObservable( rootStore );
    return <storeContext.Provider value={store}>{children}</storeContext.Provider>
}

export const useStore = () => {
    const store = React.useContext(storeContext);
    if ( !store ) {
        throw new Error( "useStore must be used within a StoreProvider" );
    }

    return store;
};
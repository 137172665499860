/* tslint:disable */
/* eslint-disable */
/**
 * Conjunct4
 * Tournament api for connect 4.
 *
 * The version of the OpenAPI document: 0.2.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RoundHeader } from './RoundHeader';
import {
    RoundHeaderFromJSON,
    RoundHeaderFromJSONTyped,
    RoundHeaderToJSON,
} from './RoundHeader';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * Game information. If the game is an open game, the enemy is not defined.
 * @export
 * @interface Game
 */
export interface Game {
    /**
     * Id of the match.
     * @type {string}
     * @memberof Game
     */
    id: string;
    /**
     * Id of the tournament this game is a part of. Returned if the game is a part of a tournament.
     * @type {string}
     * @memberof Game
     */
    tournament?: string;
    /**
     * Rounds associated with the match.
     * @type {Array<RoundHeader>}
     * @memberof Game
     */
    rounds: Array<RoundHeader>;
    /**
     * 
     * @type {User}
     * @memberof Game
     */
    enemy?: User;
    /**
     * How many rounds should a player win to win the game.
     * @type {number}
     * @memberof Game
     */
    winsRequired?: number;
    /**
     * Time in seconds until the game. Goes negative after first round goes into `starting` state or the game is discarded.
     * @type {number}
     * @memberof Game
     */
    eta: number;
}

/**
 * Check if a given object implements the Game interface.
 */
export function instanceOfGame(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "rounds" in value;
    isInstance = isInstance && "eta" in value;

    return isInstance;
}

export function GameFromJSON(json: any): Game {
    return GameFromJSONTyped(json, false);
}

export function GameFromJSONTyped(json: any, ignoreDiscriminator: boolean): Game {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'tournament': !exists(json, 'tournament') ? undefined : json['tournament'],
        'rounds': ((json['rounds'] as Array<any>).map(RoundHeaderFromJSON)),
        'enemy': !exists(json, 'enemy') ? undefined : UserFromJSON(json['enemy']),
        'winsRequired': !exists(json, 'winsRequired') ? undefined : json['winsRequired'],
        'eta': json['eta'],
    };
}

export function GameToJSON(value?: Game | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'tournament': value.tournament,
        'rounds': ((value.rounds as Array<any>).map(RoundHeaderToJSON)),
        'enemy': UserToJSON(value.enemy),
        'winsRequired': value.winsRequired,
        'eta': value.eta,
    };
}


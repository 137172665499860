import { useState } from "react";
import { Spinner } from "react-bootstrap"
import { useTimeout } from "usehooks-ts";

interface LoadingProps {
    loading? : boolean,
    minimum? : number,
    variant? : string
    size? : 'sm',
    className? : string,
    style? : React.CSSProperties,
    condensed? : boolean,
    children?: React.ReactNode,
    animation?: "border"|"grow"
}

export default function Loading({
    children = [],
    loading = false,
    minimum = 500,
    variant = "primary",
    animation = "border",
    size = undefined,
    className = "",
    style = {},
    condensed = false
} : LoadingProps) {
    const [show, setShow] = useState(false);

    useTimeout(() => {
        setShow(true);
    }, minimum);

    let containerStyle : Record<string, string> = {
        textAlign: "center"
    }

    if ( !condensed ) {
        containerStyle = {
            ...containerStyle,
            width : "100%"
        }
    } else {
        containerStyle = {
            ...containerStyle,
            display : "inline-block"
        }
    }


    if ( variant === 'button-loader' ) {
        variant = 'light';
        containerStyle = {
            ...containerStyle,
            marginRight: "4px",
            position: "relative",
            top: "1px"
        };
    }

    return <>
        {
            (loading || !show)
                ? (
                    <div className={className} style={{ ...containerStyle, ...style }}>
                        <Spinner animation={animation} size={size} variant={variant} />
                    </div>
                )
                : children
        }
    </>;
}
/* tslint:disable */
/* eslint-disable */
/**
 * Conjunct4
 * Tournament api for connect 4.
 *
 * The version of the OpenAPI document: 0.2.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * A single move.
 * @export
 * @interface RoundAllOfHistoryMoves
 */
export interface RoundAllOfHistoryMoves {
    /**
     * 0 indexed column the move was in.
     * @type {number}
     * @memberof RoundAllOfHistoryMoves
     */
    column: number;
    /**
     * Time in seconds the move took.
     * @type {number}
     * @memberof RoundAllOfHistoryMoves
     */
    time: number;
}

/**
 * Check if a given object implements the RoundAllOfHistoryMoves interface.
 */
export function instanceOfRoundAllOfHistoryMoves(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "column" in value;
    isInstance = isInstance && "time" in value;

    return isInstance;
}

export function RoundAllOfHistoryMovesFromJSON(json: any): RoundAllOfHistoryMoves {
    return RoundAllOfHistoryMovesFromJSONTyped(json, false);
}

export function RoundAllOfHistoryMovesFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoundAllOfHistoryMoves {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'column': json['column'],
        'time': json['time'],
    };
}

export function RoundAllOfHistoryMovesToJSON(value?: RoundAllOfHistoryMoves | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'column': value.column,
        'time': value.time,
    };
}

